import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlashMessagesService } from './flash-messages.service';
import { FlashMessagesComponent } from './flash-messages.component';
@NgModule({
  declarations: [FlashMessagesComponent],
  imports: [
    CommonModule
  ],
  exports: [FlashMessagesComponent],
  providers: [FlashMessagesService]
})
export class FlashMessagesModule { }
