import { Directive, ElementRef, OnInit , Input, Renderer2 } from '@angular/core';
import { AdminPermissions } from '../permissions/admin-permissions';
import { RetailerPermissions } from '../permissions/retailer-permissions';
import { ViewEvidenceAdminPermissions } from '../permissions/view-evidence-admin-permissions';
import { StoreAdminPermissions } from '../permissions/store-admin-permission';
import { RateEvidenceAdminPermissions } from '../permissions/rate-evidence-admin-permissions';
import { CampaignAdminPermissions } from '../permissions/campaign-admin-permissions';
import { RetailerAdminPermissions } from '../permissions/retailer-admin-permissions';

@Directive({
    selector: '[hasPermission]'
})
export class ShowIfAuthorizedDirective implements OnInit {
    @Input('hasPermission') module: String; // Required permission passed in
    @Input() permission: String;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef, private adminPermision: AdminPermissions, 
        private retaierPermission: RetailerPermissions,
        private viewEvidenceAdminPermission: ViewEvidenceAdminPermissions,
        private rateEvidenceAdminPermission: RateEvidenceAdminPermissions,
        private storeAdminPermission: StoreAdminPermissions,
        private campaignAdminPermission: CampaignAdminPermissions,
        private retailerAdminPermission: RetailerAdminPermissions) {}

    ngOnInit() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.el.nativeElement.style.display = 'none';
        if (user && user.user_type === 6) {
            if (user.multiuser_group.indexOf('store_admin') > -1){
                this.validatePermission(this.storeAdminPermission);
            }
            if (user.multiuser_group.indexOf('view_evidence_admin') > -1){
                this.validatePermission(this.viewEvidenceAdminPermission);
            }

            if (user.multiuser_group.indexOf('rate_evidence_admin') > -1){
                this.validatePermission(this.rateEvidenceAdminPermission);
            }
            if (user.multiuser_group.indexOf('campaign_admin') > -1){
                this.validatePermission(this.campaignAdminPermission);
            }
            if (user.multiuser_group.indexOf('retailer_admin') > -1){
                this.validatePermission(this.retailerAdminPermission);
            }
        }else if (user && user.user_type === 2) {
            this.validatePermission(this.retaierPermission);
        }else if(user && user.user_type === 4){
            this.validatePermission(this.adminPermision);
        }
    }

    validatePermission(role) {
        if (this.module && this.permission){
            if (role.hasModulePermission(this.module) && role.hasPermissionFor(this.module, this.permission)){
                this.el.nativeElement.style.display = 'inline-grid';
            }

        }else if(this.module && !this.permission){
            if (role.hasModulePermission(this.module)){
                this.el.nativeElement.style.display = 'inline-grid';
            }
        }
    }
}