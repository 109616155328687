import { Injectable } from '@angular/core';
import { ModulePermissions } from './module-permissions';


@Injectable({
  providedIn: 'root'
})


export class StoreAdminPermissions extends ModulePermissions {
    private modulesPermissions =['retailer'];

    hasModulePermission(module) {
        return this.modulesPermissions.indexOf(module) > -1;
    }

    hasPermissionFor(moduleName, permission) {
        if(moduleName == 'retailer'){
            return this.retailerModule().indexOf(permission) > -1;
        }
    }

    retailerModule(){
        return ['view_companies', 'view_stores', 'manage_stores', 'view_staff_count']
    }

}

