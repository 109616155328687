import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Output() showSideNav: EventEmitter<any> = new EventEmitter();
  isAdmin = false;
  retailerId = null;
  retailerModuleUrl= '';
  constructor(private router: Router, ) { }

  ngOnInit() {
    this.nav();
    let user = JSON.parse(localStorage.getItem('user'));
    this.retailerId = user.id
    this.isAdmin = user.user_type === 4
    if (user.user_type == 2){
      this.retailerModuleUrl = '/retailers/retailer-details/'+this.retailerId+'/details/'
    }else if (user.user_type == 6 && user.multiuser_group.indexOf('retailer_admin') > -1){
      this.retailerModuleUrl = '/retailers/retailer-details/'+this.retailerId+'/company-details/'
    }
    
  }

  nav() {
    this.showSideNav.emit(false);
  }

}
