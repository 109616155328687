// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// Dev Env

// export const environment = {
//   production: false,
//   apiURL: 'http://192.168.0.30:8002/',
//   apiVersion: 'v1',
//   username: 'appuser@mailinator.com',
//   password: 'Admin@123456',
//   client_id: 'TqoZXr4Vo5DjKffUltzZJQB2tDpAlckhZ0K9a2j0',
//   client_secret: 'wZ00ArZ8mQKxVOWDx9McIEalLACue9Qm8A4qSH2U8AsNERhd05igOF9mtihAfIjkABUMQAQ2aiIpC8n1An0Q82LkkLlhKp6LvNDW4E5Y78ZsG4KCiBqS9aDNqJiSKmTd',
//   // client_id: 'U0TqR7bWfHSBg4sDVQY3fiPgxA4Z0X8nnn3M616h',
//   // client_secret: 'cB3Np8EDPOMxO2KFahzFqhKpWHlu81S63O6yow76vIQmUlntiDrGmj4iWfhRd8x9HDiftobGYd0h1hvPCAevXgl1SyoVfQcFXLH8wqzoK7ig0bWBE6DIGKp1pSjXky0h',
//   // username: 'appuser@hubpix-netsol.com',
//   // password: 'Admin@12321',
//   // client_id: 'slDjNejHyidTymQenkwdCh2z83ntLbKgj7aYXpVF',
//   s3_path: 'http://shb-hubpix.s3.amazonaws.com/',
//   // client_secret: 'ZYWKmiYTBMSxWMJEUpuEosPqcQBhZ4Aib7O1QGVtAmKE1BD0ydr1hMhDrIIx6ondqXJvJIcemGj6n0KNa0cYc28Bq0IS8DuUoz8uwHXCYu3aUYedp425tYxdQrNMYm6w',
//   grant_type: 'password',
//   address_lookup_key: 'YU99-UT62-RZ43-JH12',
//   dropdown_limit: 100
// };

// QC env
// export const environment = {
//   production: true,
//   apiURL: 'http://192.168.0.30:8000/',
//   apiVersion: 'v1',
//   username: 'appuser@mailinator.com',
//   password: 'admin@123456',
//   client_id: 'U0TqR7bWfHSBg4sDVQY3fiPgxA4Z0X8nnn3M616h',
//   client_secret: 'cB3Np8EDPOMxO2KFahzFqhKpWHlu81S63O6yow76vIQmUlntiDrGmj4iWfhRd8x9HDiftobGYd0h1hvPCAevXgl1SyoVfQcFXLH8wqzoK7ig0bWBE6DIGKp1pSjXky0h',
//   // username: 'appuser@hubpix-netsol.com',
//   // password: 'Admin@12321',
//   // client_id: 'slDjNejHyidTymQenkwdCh2z83ntLbKgj7aYXpVF',
//   s3_path: 'http://shb-hubpix.s3.amazonaws.com/',
//   // client_secret: 'ZYWKmiYTBMSxWMJEUpuEosPqcQBhZ4Aib7O1QGVtAmKE1BD0ydr1hMhDrIIx6ondqXJvJIcemGj6n0KNa0cYc28Bq0IS8DuUoz8uwHXCYu3aUYedp425tYxdQrNMYm6w',
//   grant_type: 'password',
//   address_lookup_key: 'YU99-UT62-RZ43-JH12',
//   dropdown_limit: 100
// };

// Stg_env
export const environment = {
  production: true,
  apiURL: 'https://api-hubpix-acl.agilecollab.com/',
  apiVersion: 'v1',
  username: 'appuser@mailinator.com',
  password: 'Admin@123456',
  client_id: 'TqoZXr4Vo5DjKffUltzZJQB2tDpAlckhZ0K9a2j0',
  client_secret: 'wZ00ArZ8mQKxVOWDx9McIEalLACue9Qm8A4qSH2U8AsNERhd05igOF9mtihAfIjkABUMQAQ2aiIpC8n1An0Q82LkkLlhKp6LvNDW4E5Y78ZsG4KCiBqS9aDNqJiSKmTd',
  s3_path: 'https://shb-hubpix.s3.amazonaws.com/',
  grant_type: 'password',
  address_lookup_key: 'YU99-UT62-RZ43-JH12',
  dropdown_limit: 100
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
