import { Injectable } from '@angular/core';

declare var HubpixToastAlert: any;

@Injectable({
  providedIn: 'root'
})

export class FlashMessagesService {

  constructor() { }
  defaults: Object = {
    'text': 'Default Text Message',
    'title': '',
    'type': 'success',
    'icon': '',
    'timeout': 10000
  };

  show(text?: string, options?: Object) {
    options = Object.assign(this.defaults, options, { 'text': text, 'type': 'success', 'timeout': 4000 });
    HubpixToastAlert.create(options);
  }

  error(text?: string, options?: Object) {
    options = Object.assign(this.defaults, options, { 'text': text, 'type': 'error', 'timeout': 4000 });
    HubpixToastAlert.create(options);
  }

  info(text?: string, options?: Object) {
    options = Object.assign(this.defaults, options, { 'text': text, 'type': 'info', 'timeout': 4000 });
    HubpixToastAlert.create(options);
  }

  warning(text?: string, options?: Object) {
    options = Object.assign(this.defaults, options, { 'text': text, 'type': 'warning', 'timeout': 4000 });
    HubpixToastAlert.create(options);
  }

  grayOut(value: boolean) {

  }
}
