import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptionsArgs, Response, XHRBackend } from '@angular/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from '../loader/loader.service';

import { AngularBearerRequestOptions } from './bearer.request.options';

@Injectable()
export class HttpService extends Http {

  private loaderCount = 0;

  constructor(
    backend: XHRBackend,
    defaultOptions: AngularBearerRequestOptions,
    private loaderService: LoaderService,
    private router: Router,
  ) {
    super(backend, defaultOptions);
  }
  private showLoader(): void {
    this.loaderCount = this.loaderCount + 1;
    this.loaderService.show();
  }

  private loaderAction(hideLoading): void {
    if (!hideLoading) {
      this.showLoader();
    } else {
      this.loaderCount = this.loaderCount + 1;
    }
  }

  get(url: string, hideLoading = '', options?: RequestOptionsArgs): Observable<any> {
    this.loaderAction(hideLoading);
    return super.get(encodeURI(url), this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        if (error.status == 403){
          window.location.href = '/not-found';
        }
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  post(url: string, body: any, hideLoading = '', options?: RequestOptionsArgs): Observable<any> {
    this.loaderAction(hideLoading);
    return super.post(encodeURI(url), body, this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  put(url: string, body: any, hideLoading = '', options?: RequestOptionsArgs): Observable<Response> {
    this.loaderAction(hideLoading);
    return super.put(encodeURI(url), body, this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  /**
   * Performs a request with `delete` http method.
   */
  delete(url: string, hideLoading = '', options?: RequestOptionsArgs): Observable<Response> {
    this.loaderAction(hideLoading);
    return super.delete(encodeURI(url), this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  /**
   * Performs a request with `patch` http method.
   */
  patch(url: string, body: any, hideLoading = '', options?: RequestOptionsArgs): Observable<Response> {
    this.loaderAction(hideLoading);
    return super.patch(encodeURI(url), body, this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  /**
   * Performs a request with `head` http method.
   */
  head(url: string, hideLoading = '', options?: RequestOptionsArgs): Observable<Response> {
    this.loaderAction(hideLoading);
    return super.head(encodeURI(url), this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  /**
   * Performs a request with `options` http method.
   */
  options(url: string, hideLoading = '', options?: RequestOptionsArgs): Observable<Response> {
    this.loaderAction(hideLoading);
    return super.options(encodeURI(url), this.requestOptions(options))
      .catch(this.onCatch)
      .do((res: Response) => {
        this.onSuccess(res);
      }, (error: any) => {
        this.onError(error);
      })
      .finally(() => {
        this.onEnd();
      });
  }

  private requestOptions(options?: RequestOptionsArgs): RequestOptionsArgs {
    if (options == null) {
      options = new AngularBearerRequestOptions();
    }

    if (options.headers == null) {
      options.headers = new Headers();
    }

    return options;
  }

  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    return Observable.throw(error);
  }

  private onSuccess(response: Response): void {
    const res = response.json();
    if (res.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('is_remember');
      window.location.href = 'users/login';
    } else if (res.status !== 200) {
      throw (JSON.stringify(res));
    }
  }

  private onError(response: Response): void {
    if (response.status === 404) {
      // window.location.href = 'not-found';
    }
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private hideLoader(): void {
    if (this.loaderCount <= 1) {
      this.loaderService.hide();
    }
    this.loaderCount = this.loaderCount - 1;
  }
}
