import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Hubpix';
  constructor(private ngxRolesService: NgxRolesService) { }

  ngOnInit(): void {
    // this.permissionsService.loadPermissions(['ADMIN']);
    this.ngxRolesService.addRole('ADMIN', ['listEvents', 'editEvents']);

  }
}
