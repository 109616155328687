import { XHRBackend } from '@angular/http';
import { Router } from '@angular/router';
import { AngularBearerRequestOptions } from './http/bearer.request.options';
import { HttpService } from './http/http.service';
import { LoaderService } from './loader/loader.service';

function httpServiceFactory(
    backend: XHRBackend,
    options: AngularBearerRequestOptions,
    loaderService: LoaderService,
    router: Router) {
    return new HttpService(backend, options, loaderService, router);
}
export { httpServiceFactory };
