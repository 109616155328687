import { FlashMessagesInterface } from './flash-messages.interface';

export class FlashMessage implements FlashMessagesInterface {
    static nextId = 0;

    id: number = (FlashMessage.nextId++);
    text = 'default text';
    cssClass = '';

    constructor(text?: string, cssClass?: string) {
        this.text = text;
        this.cssClass = cssClass;
    }
}