import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.css']
})
export class PrivateComponent implements OnInit {

  sideNavVisible = false;
  
  showSideNav(e){
    this.sideNavVisible = e;
  }

  closeMenu(){
    this.sideNavVisible = false;
  }

  constructor() { }

  ngOnInit() {
  }

}
