import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule, ModalModule } from 'ngx-bootstrap';
import 'url-search-params-polyfill';
import { AppRoutingModule } from './app-routing-module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { HeaderComponent } from './header/header.component';
import { PrivateComponent } from './layout/private/private.component';
import { PublicComponent } from './layout/public/public.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PublicGuard } from './shared/guards/public.gaurd';
import { SharedModule } from './shared/shared.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { Ng5SliderModule } from 'ng5-slider';
import { ConfirmDialogService } from './shared/confirm-dialog/confirm-dialog.service';
import { LoaderService } from './shared/loader/loader.service';
import { FlashMessagesModule } from './shared/flash-messages/flash-messages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NiceSelectModule } from 'ng-nice-select';
import { AdminGuard } from './shared/guards/admin-guard';
import { ShowIfAuthorizedDirective } from './shared/directives/show-if-authorized';
import { HighlightDirective } from './highlight.directive';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AdminPermissions } from './shared/permissions/admin-permissions';
import {  ViewRetailerGuard } from './shared/guards/view-retailer-guard';
import { ViewEvidenceAdminGuard } from './shared/guards/view-evidence-admin-guard';
import { ManageCompanyStoreGuard } from './shared/guards/manage-company-store-guard';
import { ViewCampaignGuard } from './shared/guards/view-campaign-guard';
import { ManageCampaignGuard } from './shared/guards/manage-campaign-guard';
import { ManageRetailerGuard } from './shared/guards/manage-retailer-guard';
import { StoreAdminGuard } from './shared/guards/store-admin-guard';
@NgModule({
  declarations: [
    AppComponent,
    PublicComponent,
    PrivateComponent,
    HeaderComponent,
    SidebarComponent,
    NotFoundComponent,
  ],
  imports: [
    NiceSelectModule,
    BrowserAnimationsModule,
    Ng5SliderModule,
    AngularFontAwesomeModule,
    ShowHidePasswordModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    BrowserModule,
    HttpModule,
    SharedModule,
    AppRoutingModule,
    FlashMessagesModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    ConfirmDialogService,
    LoaderService,
    AppConfig,
    AuthGuard,
    AdminGuard,
    ViewCampaignGuard,
    ManageCampaignGuard,
    ViewRetailerGuard,
    ManageRetailerGuard,
    ViewEvidenceAdminGuard,
    ManageCompanyStoreGuard,
    StoreAdminGuard,
    PublicGuard
  ],
  bootstrap: [AppComponent,]
})

export class AppModule { DatepickerModule; }
