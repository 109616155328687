import { Component, OnInit } from '@angular/core';
import { FlashMessage } from './flash-messages';
import { FlashMessagesInterface } from './flash-messages.interface';
import { FlashMessagesService } from './flash-messages.service';

@Component({
    selector: 'app-flash-messages',
    template: `
      <div id="flashMessages" class="flash-messages {{classes}}">
          <div id="grayOutDiv"></div>
          <div class="alert flash-message {{message.cssClass}}" *ngFor='let message of messages'>
              <p>{{message.text}}</p>
          </div>
      </div>
  `
})
export class FlashMessagesComponent implements OnInit {
    private _defaults = {
        text: 'default message',
        cssClass: ''
    };

    text: string;
    messages: FlashMessagesInterface[] = [];
    classes = '';
    _grayOut = false;

    constructor(private _flashMessagesService: FlashMessagesService) {
        this._flashMessagesService.show = this.show.bind(this);
        this._flashMessagesService.grayOut = this.grayOut.bind(this);
    }

    ngOnInit() { }

    show(text?: string, options = {}): void {

        const defaults = {
            timeout: 2500,
            cssClass: ''
        };

        for (var attrname in options) { (<any>defaults)[attrname] = (<any>options)[attrname]; }
        const message = new FlashMessage(text, defaults.cssClass);
        this.messages.push(message);

        window.setTimeout(() => {
            this._remove(message);
        }, defaults.timeout);
    }

    grayOut(value = false) {
        this._grayOut = value;
    }

    private _remove(message: FlashMessagesInterface) {
        this.messages = this.messages.filter((msg) => msg.id !== message.id);
    }
}
