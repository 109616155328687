import { Injectable } from '@angular/core';
import { ModulePermissions } from './module-permissions';


@Injectable({
  providedIn: 'root'
})


export class RetailerAdminPermissions extends ModulePermissions {
    private modulesPermissions =['campaigns', 'brands', 'retailer'];

    hasModulePermission(module) {
        return this.modulesPermissions.indexOf(module) > -1;
    }

    hasPermissionFor(moduleName, permission) {
        if(moduleName == 'brands'){
            return this.brandsModule().indexOf(permission) > -1;
        }else if(moduleName == 'campaigns'){
            return this.campaignModule().indexOf(permission) > -1;
        }else if(moduleName == 'retailer'){
            return this.retailerModule().indexOf(permission) > -1;
        }
    }

    brandsModule() {
        return [
            'view_supplier', 'manage_supplier', 'view_brands', 'manage_brands'
        ]
    }

    campaignModule() {
        return ['view_campaign', 'campaign_detail', 'manage_campaign',
            'view_evidence','manage_evidence', 'view_outstanding_task', 'manage_outstanding_task'
        ]
    }

    retailerModule(){
        return ['view_companies', 'manage_companies', 'view_stores', 'manage_stores', 'view_staff_count']
    }

}

