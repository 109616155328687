import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()

export class StoreAdminGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = JSON.parse(localStorage.getItem('user'));
        debugger
        if (user && user.user_type == 6 && user.multiuser_group.indexOf('store_admin') > -1) {
            this.router.navigate(['/retailers/retailer-details/'+user.id+'/company-details/']);
            return false;
        } else {
            return true;
        }
    }
}
