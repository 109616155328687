import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()

export class ViewCampaignGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = JSON.parse(localStorage.getItem('user'));
        let viewCampaignGroups = [
            'retailer_admin', 'campaign_admin', 'view_evidence_admin', 'rate_evidence_admin'
        ]
        if (user && ([2, 4].indexOf(user.user_type) > -1 || (user.user_type == 6 && viewCampaignGroups.indexOf(user.multiuser_group[0]) > -1))) {
            return true;
        } else {
            this.router.navigate(['not-found']);
            return false;
        }
    }
}
