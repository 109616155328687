import { Injectable } from '@angular/core';
import { ModulePermissions } from './module-permissions';


@Injectable({
  providedIn: 'root'
})


export class AdminPermissions extends ModulePermissions {
    private modulesPermissions =['retailer-signup', 'brands', 'master-data', 'manage-retailer', 'campaigns', 'retailer',];
    // constructor(
    //     private modulePermission: ModulePermissions
    // ) { }

    hasModulePermission(module) {
        return this.modulesPermissions.indexOf(module) > -1;
    }

    hasPermissionFor(moduleName, permission) {
    
        return true;
        // if (moduleName == 'retailer_signup'){
        //     return this.retailerSignupModule().indexOf(permission) > -1;
        // }
    }

    retailerSignupModule() {
        return ['add_retailer', 'edit-retailer']
        // this.retailerSignup().push()
    }

}

