import { Injectable } from '@angular/core';
import { ModulePermissions } from './module-permissions';


@Injectable({
  providedIn: 'root'
})


export class RateEvidenceAdminPermissions extends ModulePermissions {
    private modulesPermissions =['campaigns', 'campaign'];

    hasModulePermission(module) {
        return this.modulesPermissions.indexOf(module) > -1;
    }

    hasPermissionFor(moduleName, permission) {
        if(this.modulesPermissions.indexOf(moduleName) > -1){
            return this.campaignModule().indexOf(permission) > -1;
        }
    }

    campaignModule() {
        return ['view_campaign', 'campaign_detail', 'view_evidence', 'manage_evidence', 'view_outstanding_task', 'manage_outstanding_task',  'view_participating_stores', 'view_notes',
        'view_products']
    }

}

