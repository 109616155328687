import { Directive, ElementRef, OnInit , Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[isCampaignOwner]'
})
export class IsCampaignOwnerDirective implements OnInit {
    @Input('isCampaignOwner') campaignOwner: number; // Required permission passed in
    // @Input() campaignOwner: number;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef) {}

    ngOnInit() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.el.nativeElement.style.display = 'none';
        if (user && user.id == this.campaignOwner) {
            this.el.nativeElement.style.display = 'inline-grid';
        }
    }

}