import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {
  constructor(Element: ElementRef) { 
    console.log(Element);
    Element.nativeElement.innerText="ddfdfdffdfdf"
  }

  ngOnInit() {
    alert("dd")
  }
}
