export class ConfirmDialog {
    title: string;
    confirm_text: string;
    confirm_action: Function;
    cancel_action: Function;
    public constructor(title?: string, confirm_text?: string, confirm_action?: Function, cancel_action?: Function) {
        this.title = title;
        this.confirm_text = confirm_text;
        this.confirm_action = confirm_action;
        this.cancel_action = cancel_action;
    }
}