import { Injectable } from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

@Injectable()

export class PublicGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('user') && localStorage.getItem('user') !== 'undefined') {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user.user_type == 6 && user.multiuser_group.indexOf('store_admin') > -1){
                this.router.navigate(['/retailers/retailer-details/'+user.id+'/company-details/']);
            }else{
                this.router.navigate(['campaigns']);
            }
            return false;
        }
        return true;
    }
}
