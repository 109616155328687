import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../environments/environment';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'emailMask' })
export class EmailMaskPipe implements PipeTransform {
    transform(value) {
        if (value) {
            const email = value.split('@');
            const first_char = email[0].charAt(0);
            return first_char + '********@' + email[1];
        } else {
            return value;
        }
    }
}

@Pipe({ name: 'dayplural' })
export class DayPluralPipe implements PipeTransform {
    transform(value: string, text): string {
        if (parseInt(value, 10) > 1) {
            return value + ' ' + text + 's';
        } else {
            return value + ' ' + text;
        }
    }
}

@Pipe({ name: 'converttopositive' })
export class convertToPositivePipe implements PipeTransform {
    transform(value, text): string {
        if (value > 1) {
            return value + ' ' + text + 's Left';
        } else if (value < 0) {
            const final_value = Math.abs(value);
            if (final_value > 1) {
                return final_value + ' ' + text + 's Past';
            } else {
                return final_value + ' ' + text + ' ' + 'Past';
            }
        } else {
            return value + ' ' + text + ' ' + 'Left';
        }
    }
}

@Pipe({ name: 'campaignstatus' })
export class CampaignStatus implements PipeTransform {
    transform(value: string): string {
        if (value) {
            let value_obj = parseInt(value, 10);
            let campaign_status = '';
            if (value_obj === 1) {
                campaign_status = 'Active';
            } else if (value_obj === 2) {
                campaign_status = 'Completed';
            } else if (value_obj === 3) {
                campaign_status = 'Opted In';
            }
            return campaign_status
        } else {
            return value;
        }
    }
}

@Pipe({ name: 'greater100' })
export class Greater100 implements PipeTransform {
    transform(value: string): string {
        if (parseInt(value, 10) > 100) {
            return '100 +';
        } else {
            return value;
        }
    }
}

@Pipe({ name: 'adds3path' })
export class AddS3Path implements PipeTransform {
    transform(value: string): string {
        if (value) {
            return environment.s3_path + value;
        } else {
            return value;
        }
    }
}

@Pipe({ name: 'dateFormatPipe' })
export class DateFormatPipe implements PipeTransform {
    transform(value) {
        if (value) {
            const date = new Date(value)
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
                return date;
            } else {
                return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
            }
        }
        else {
            return value;
        }
    }
}

@Pipe({ name: 'enumKeyValuelist' })
export class EnumKeyValueListPipe implements PipeTransform {

    transform(value: any): any {
        const items: any[] = [];
        for (let key in value) {
            var isValueProperty = parseInt(key, 10) >= 0;
            if (!isValueProperty) continue;
            items.push({ value: key, key: value[key] });
        }
        return items;
    }
}


@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

}

@Pipe({ name: 'textConvert' })
export class TextConvertPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            value = 'Yes';
            return value;
        } else {
            value = 'No';
            return value;
        }
    }
}
