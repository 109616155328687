import { Injectable } from '@angular/core';
import { ModulePermissions } from './module-permissions';


@Injectable({
  providedIn: 'root'
})


export class CampaignAdminPermissions extends ModulePermissions {
    private modulesPermissions =['campaigns', 'brands'];

    hasModulePermission(module) {
        return this.modulesPermissions.indexOf(module) > -1;
    }

    hasPermissionFor(moduleName, permission) {
        if(moduleName == 'brands'){
            return this.brandsModule().indexOf(permission) > -1;
        }else if(moduleName == 'campaigns'){
            return this.campaignModule().indexOf(permission) > -1;
        }
    }

    brandsModule() {
        return [
            'view_supplier', 'manage_supplier', 'view_brands', 'manage_brands'
        ]
    }

    campaignModule() {
        return ['view_campaign', 'campaign_detail', 'manage_campaign', 'view_participating_stores', 'view_notes',
            'view_products','view_evidence','manage_evidence', 'view_outstanding_task', 'manage_outstanding_task'
        ]
    }

}

