import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ConfirmDialog } from './confirm.dialog.conf';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  confirmDialog: ConfirmDialog = null;
  @ViewChild('confirmDialogModal') public confirmDialogModal: ModalDirective;
  constructor(
    private confirmDialogService: ConfirmDialogService
  ) {
    this.confirmDialogService.show = this.show.bind(this);
    this.confirmDialogService.hide = this.hide.bind(this);
    this.confirmDialog = new ConfirmDialog();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.confirmDialogService.currentMessage.subscribe(data => {
      this.show(data.title, data.confirm_text, data.confirm_action, data.cancel_action)
    });
  }

  hide() {
    this.confirmDialog = new ConfirmDialog();
    this.confirmDialogModal.hide();
  }
  show(title?: string, confirm_text?: string, confirm_action?: Function, cancel_action?: Function) {
    if (title.length === 0) {
      return;
    }
    this.confirmDialog = new ConfirmDialog(title, confirm_text, confirm_action, cancel_action);
    this.confirmDialogModal.show();
  }
  confirmAction() {
    this.confirmDialog.confirm_action.call(this);
  }
  cancelAction() {
    this.confirmDialog.cancel_action.call(this);
    this.confirmDialogModal.hide();
  }

}
