import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor() { }

  private messageSource = new BehaviorSubject<any>({ title: '', confirm_text: '', confirm_action: Function, cancel_action: Function })
  currentMessage = this.messageSource.asObservable();

  show(title?: string, confirm_text?: string, confirm_action?: Function, cancel_action?: Function) {
    this.messageSource.next({ title: title, confirm_text: confirm_text, confirm_action: confirm_action, cancel_action: cancel_action })
  }

  confirmAction: () => void;
  cancelAction: () => void;
  hide: () => void;
}
