import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()

export class ViewEvidenceAdminGuard implements CanActivate {

    constructor(private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let user = JSON.parse(localStorage.getItem('user'));
        debugger
        if (user && user.user_type == 6 && user.multiuser_group.indexOf('view_evidence_admin') > -1) {
            return true;
        } else {
            this.router.navigate(['not-found']);
            return false;
        }
    }
}
