import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestOptions, XHRBackend } from '@angular/http';
import { ModalModule } from 'ngx-bootstrap';
// import { NgxImageZoomModule } from 'ngx-image-zoom';
import { HttpService } from './http/http.service';
import { httpServiceFactory } from './factories';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { FlashMessagesService } from './../shared/flash-messages/flash-messages.service';
import { ScrollToTopService } from '../shared/scroll-top/scroll-to-top.service';
import { DayPluralPipe, AddS3Path, DateFormatPipe, Greater100, EnumKeyValueListPipe, CampaignStatus, convertToPositivePipe, TextConvertPipe, SafeHtmlPipe } from './../core.pipes';
import { AdminPermissions } from './permissions/admin-permissions';
import { HighlightDirective } from '../highlight.directive';
import { ShowIfAuthorizedDirective } from './directives/show-if-authorized';
import { IsCampaignOwnerDirective } from './directives/is-campaign-owner';



@NgModule({
  declarations: [
    LoaderComponent,
    ConfirmDialogComponent,
    HighlightDirective,
    ShowIfAuthorizedDirective,
    IsCampaignOwnerDirective,
    DayPluralPipe, AddS3Path, DateFormatPipe, Greater100, EnumKeyValueListPipe, CampaignStatus, convertToPositivePipe, TextConvertPipe, SafeHtmlPipe, 
    
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    // NgxImageZoomModule.forRoot()
  ],
  exports: [
    LoaderComponent,
    ConfirmDialogComponent,
    HighlightDirective,
    ShowIfAuthorizedDirective,
    IsCampaignOwnerDirective,
    DayPluralPipe, AddS3Path, DateFormatPipe, Greater100, EnumKeyValueListPipe, CampaignStatus, convertToPositivePipe, TextConvertPipe, SafeHtmlPipe
  ],
  providers: [
    FlashMessagesService,
    LoaderService,
    ScrollToTopService,
    {
      provide: HttpService,
      useFactory: httpServiceFactory,
      deps: [XHRBackend, RequestOptions, LoaderService]
    }
  ]
})
export class SharedModule { }
